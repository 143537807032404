import React from "react";
import { ChatMessage } from "../types";

export default async function sendQuestion(
	question: string,
	setCanAskQuestion: React.Dispatch<React.SetStateAction<boolean>>,
	setChatMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
	chatMessages: ChatMessage[],
	uploadedFiles: any,
	userId: string,
) {
	// Disabling the question input until the current question is processed
	setCanAskQuestion(false);

	// Add the user's question to the chatMessages
	setChatMessages((prevMessages) => [
		...prevMessages,
		{ type: "question", text: question },
	]);

	const askRequest = {
		question: question,
		chat_buffer: chatMessages
			.map((msg) => `${msg.type}: ${msg.text}`)
			.join("\n"),
		filenames: Object.keys(uploadedFiles.current).filter(
			(fn) => fn !== "UPLOAD-NEW-FILE",
		),
	};

	try {
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/api/ask`,
			{
				method: "POST",
				body: JSON.stringify(askRequest),
				headers: {
					"Content-Type": "application/json",
					"user-id": userId,
				},
			},
		);

		setChatMessages((prevMessages) => [
			...prevMessages,
			{ type: "answer", text: "" },
		]);

		const reader = response.body.getReader();
		let ongoingMessage = ""; // This will store the ongoing construction of the message

		while (true) {
			const { value, done } = await reader.read();
			if (done) break;

			const newToken = new TextDecoder().decode(value);
			ongoingMessage += newToken
				.replace(/\n\n\n/g, "TRIPLE_RETURN")
				.replace(/\n\n/g, "DOUBLE_RETURN")
				.replace(/\n/g, "")
				.replace(/TRIPLE_RETURN/g, "<br><br>\n\n")
				.replace(/DOUBLE_RETURN/g, "\n"); // Append the new token to the ongoing message
			console.log(newToken);

			// Update the last message (which is the answer) in the chatMessages state
			setChatMessages((prevMessages) => {
				const updatedMessages = [...prevMessages];
				const lastMessageIndex = updatedMessages.length - 1;
				updatedMessages[lastMessageIndex] = {
					...updatedMessages[lastMessageIndex],
					text: ongoingMessage,
				};
				return updatedMessages;
			});
		}
	} catch (error) {
		console.error("Error while sending question:", error);
	}

	// Re-enabling the question input
	setCanAskQuestion(true);
}
