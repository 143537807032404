import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const UserContext = React.createContext("");

export const UserProvider = ({ children }: any) => {
	const [userId, setUserId] = useState("");

	useEffect(() => {
		const newUserId = uuidv4(); // Generating a new UUID on mount
		setUserId(newUserId);
	}, []);

	return (
		<UserContext.Provider value={userId}> {children} </UserContext.Provider>
	);
};
