import { Worker } from "@react-pdf-viewer/core";
import Settings from "../../themes/settings";
import ThumbnailsGallery from "../../components/ThumbnailsGallery";
import PDFViewer from "../../features/PDFViewer/PDFViewer";
import FileUpload from "../../features/FileUpload/FileUpload";
import React from "react";

interface ViewerPanel {
	currentFile: string;
	setCurrentFile: React.Dispatch<string>;
	uploadedFiles: React.RefObject<any>;
	handleFilesChange: any;
	highlightPluginInstance: any;
}
export default function ViewerPanel({
	currentFile,
	setCurrentFile,
	uploadedFiles,
	handleFilesChange,
	highlightPluginInstance,
}: ViewerPanel) {
	return (
		<div className="flex flex-col flex-auto h-full max-h-full">
			<div className="flex p-3 bg-secondary shadow">
				<h3 className="px-4 text-lg text-text-inv text-center">
					{Settings.left}
				</h3>
			</div>
			<div className="flex flex-col flex-auto h-full max-h-full bg-background">
				<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
					{currentFile ? (
						<div className="h-[calc(100vh-48px)] overflow-y-hidden">
							{Settings.panels.includes("Thumbnails") ? (
								<ThumbnailsGallery
									uploadedFiles={uploadedFiles}
									currentFile={currentFile}
									setCurrentFile={setCurrentFile}
									handleFilesChange={handleFilesChange}
								/>
							) : (
								<></>
							)}
							<PDFViewer
								uploadedFiles={uploadedFiles}
								currentFile={currentFile}
								highlightPluginInstance={highlightPluginInstance}
							/>
						</div>
					) : (
						<div className="text-center mt-[30vh]">
							<FileUpload handleFilesChange={handleFilesChange} />
						</div>
					)}
				</Worker>
			</div>
		</div>
	);
}
