import React, { ReactElement } from "react";
import Settings from "../../../themes/settings";

interface Reference {
	text: string;
	onClick: React.MouseEventHandler;
	index: number;
}
export function Reference({ text, onClick, index }: Reference) {
	return (
		<span
			className="rounded-full bg-sidebar px-1.5 ml-1 text-text-inv cursor-pointer"
			onClick={onClick}
			key={index}
		>
			{text}
		</span>
	);
}

interface Question {
	content: ReactElement[];
	onClick: React.MouseEventHandler;
}
export function Question({ content, onClick }: Question) {
	return (
		<div
			className="py-1 cursor-pointer font-medium items-center"
			onClick={onClick}
		>
			<Settings.listIcon size={12} className="inline text-primary" />
			<span className="pl-2 hover:underline">{content}</span>
		</div>
	);
}
