interface themeDictionary {
	[key: string]: any;
}

export default function applyTheme(theme: themeDictionary) {
	const root = document.documentElement;
	Object.keys(theme).forEach((cssVar) => {
		root.style.setProperty(cssVar, theme[cssVar]);
	});
}
