import Settings from "../../themes/settings";

export default function Sidebar() {
	return (
		<aside
			className={
				"fixed top-0 bottom-0 z-50 flex h-full w-[260px] flex-none flex-col space-y-2 bg-sidebar p-2 transition-all relative"
			}
		>
			<div className="bg-sidebar">
				<div className="flex flex-col items-center justify-center">
					<img
						className="h-auto w-[250px] pb-4 border-b border-white/20"
						src={`${Settings.folder}/logo.png`}
					/>
					<span className="my-10 text-text-inv bg-warning font-medium rounded-full text-sm px-5 py-2.5 text-center my-5">
						Version de démonstration
					</span>
				</div>
			</div>
			<header className="flex items-center">
				<a
					className="flex w-[190px] flex-shrink-0 cursor-pointer items-center gap-3 rounded-md border border-white/20 p-3 text-[12.5px] leading-3 text-text-inv transition-colors duration-200 select-none hover:bg-gray-500/10"
					href="."
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="18"
						height="18"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
						className="tabler-icon tabler-icon-plus"
					>
						<path d="M12 5l0 14" />
						<path d="M5 12l14 0" />
					</svg>
					Nouveau fichier
				</a>
				<button className="ml-2 flex flex-shrink-0 cursor-pointer items-center gap-3 rounded-md border border-white/20 p-3 text-[12.5px] leading-3 text-text-inv transition-colors duration-200 hover:bg-gray-500/10">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="18"
						height="18"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
						className="tabler-icon tabler-icon-folder-plus"
					>
						<path d="M12 19h-7a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2h4l3 3h7a2 2 0 0 1 2 2v3.5" />
						<path d="M16 19h6" />
						<path d="M19 16v6" />
					</svg>
				</button>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="32"
					height="32"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="ml-1 hidden cursor-pointer p-1 text-text-inv hover:text-neutral-400 sm:flex"
				>
					<path d="M4 12l10 0" />
					<path d="M4 12l4 4" />
					<path d="M4 12l4 -4" />
					<path d="M20 4l0 16" />
				</svg>
			</header>
			<div className="relative flex items-center">
				<input
					className="w-full flex-1 rounded-md border border-neutral-600 bg-[#eee] px-4 py-3 pr-10 text-[12px] leading-3 text-text-inv"
					type="text"
					placeholder="Rechercher parmi les fichiers..."
				/>
			</div>
			<div className="flex-grow overflow-y-auto overflow-x-clip">
				<div className="flex w-full flex-col gap-1 pt-2">
					<div className="relative flex items-center">
						<button
							className="flex w-full cursor-pointer items-center gap-3 rounded p-3 text-sm transition-colors duration-200 hover:bg-secondary-light  bg-secondary"
							draggable="true"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="18"
								height="18"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
								className="tabler-icon tabler-icon-message"
							>
								<path d="M8 9h8" />
								<path d="M8 13h6" />
								<path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
							</svg>
							<div className="text-text-inv relative max-h-5 flex-1 text-[12.5px] leading-3 overflow-hidden text-ellipsis whitespace-nowrap break-all text-left pr-12">
								Conversation 1
							</div>
						</button>
						<div className="visible absolute right-1 z-10 flex text-text-inv">
							<button className="min-w-[20px] p-1 text-neutral-400 hover:text-neutral-100">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="18"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
									className="tabler-icon tabler-icon-pencil"
								>
									<path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
									<path d="M13.5 6.5l4 4" />
								</svg>
							</button>
							<button className="min-w-[20px] p-1 text-neutral-400 hover:text-neutral-100">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="18"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
									className="tabler-icon tabler-icon-trash"
								>
									<path d="M4 7l16 0" />
									<path d="M10 11l0 6" />
									<path d="M14 11l0 6" />
									<path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
									<path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
								</svg>
							</button>
						</div>
					</div>
					<div className="relative flex items-center">
						<button
							className="flex w-full cursor-pointer items-center gap-3 rounded p-3 text-sm transition-colors duration-200 hover:bg-[#475569]  "
							draggable="true"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="18"
								height="18"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
								className="tabler-icon tabler-icon-message"
							>
								<path d="M8 9h8" />
								<path d="M8 13h6" />
								<path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
							</svg>
							<div className="text-text-inv relative max-h-5 flex-1 text-[12.5px] leading-3 overflow-hidden text-ellipsis whitespace-nowrap break-all text-left pr-1">
								Conversation 2
							</div>
						</button>
					</div>
					<div className="relative flex items-center">
						<button
							className="flex w-full cursor-pointer items-center gap-3 rounded p-3 text-sm transition-colors duration-200 hover:bg-[#475569]  "
							draggable="true"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="18"
								height="18"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
								className="tabler-icon tabler-icon-message"
							>
								<path d="M8 9h8" />
								<path d="M8 13h6" />
								<path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
							</svg>
							<div className="text-text-inv relative max-h-5 flex-1 text-[12.5px] leading-3 overflow-hidden text-ellipsis whitespace-nowrap break-all text-left pr-1">
								Conversation 3
							</div>
						</button>
					</div>
				</div>
			</div>
			<div className="flex flex-col items-center space-y-1 border-t border-white/20 pt-1 text-sm">
				<a
					className="flex w-full cursor-pointer select-none items-center gap-3 rounded-md py-3 px-3 text-[12.5px] leading-3 text-text-inv transition-colors duration-200 hover:bg-gray-500/10"
					href="."
				>
					<div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
							className="tabler-icon tabler-icon-trash"
						>
							<path d="M4 7l16 0" />
							<path d="M10 11l0 6" />
							<path d="M14 11l0 6" />
							<path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
							<path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
						</svg>
					</div>
					<span>Effacer les conversations</span>
				</a>
				<input
					id="import-file"
					className="sr-only"
					tabIndex={-1}
					type="file"
					accept=".json"
				/>
				<button className="flex w-full cursor-pointer select-none items-center gap-3 rounded-md py-3 px-3 text-[12.5px] leading-3 text-text-inv transition-colors duration-200 hover:bg-gray-500/10">
					<div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
							className="tabler-icon tabler-icon-file-import"
						>
							<path d="M14 3v4a1 1 0 0 0 1 1h4" />
							<path d="M5 13v-8a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5.5m-9.5 -2h7m-3 -3l3 3l-3 3" />
						</svg>
					</div>
					<span>Importer des conversations</span>
				</button>
				<button className="flex w-full cursor-pointer select-none items-center gap-3 rounded-md py-3 px-3 text-[12.5px] leading-3 text-text-inv transition-colors duration-200 hover:bg-gray-500/10">
					<div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
							className="tabler-icon tabler-icon-file-export"
						>
							<path d="M14 3v4a1 1 0 0 0 1 1h4" />
							<path d="M11.5 21h-4.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v5m-5 6h7m-3 -3l3 3l-3 3" />
						</svg>
					</div>
					<span>Enregistrer les conversations</span>
				</button>
				<button className="flex w-full cursor-pointer select-none items-center gap-3 rounded-md py-3 px-3 text-[12.5px] leading-3 text-text-inv transition-colors duration-200 hover:bg-gray-500/10">
					<div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
							className="tabler-icon tabler-icon-moon"
						>
							<path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" />
						</svg>
					</div>
					<span>Thème sombre</span>
				</button>
				<button
					className="text-text-inv inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-accent hover:text-accent-foreground h-10 py-2 px-4 w-64 dark:border-gray-50"
					type="button"
					aria-haspopup="dialog"
					aria-expanded="false"
					aria-controls="radix-:r0:"
					data-state="closed"
				>
					Réglages
				</button>
				<div className="text-text-inv">
					Mode : <b>{process.env.REACT_APP_MODE}</b>.
				</div>
			</div>
		</aside>
	);
}
