import { ReactElement } from "react";

interface ChatTable {
	content: ReactElement[];
}
export function ChatTable({ content }: ChatTable) {
	return (
		<div className="overflow-x-auto">
			<div className="bg-primary-100 border overflow-x-scroll">
				<table className="table-auto w-full overflow-x-scroll">{content}</table>
			</div>
			<br />
		</div>
	);
}

export function ChatTHead({ content }: ChatTable) {
	return (
		<thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
			{content}
		</thead>
	);
}

export function ChatTBody({ content }: ChatTable) {
	return <tbody className="text-sm divide-y divide-gray-100">{content}</tbody>;
}
export function ChatTH({ content }: ChatTable) {
	return <th className="p-2 whitespace-nowrap">{content}</th>;
}
export function ChatTD({ content }: ChatTable) {
	return <td className="p-2 whitespace-nowrap">{content}</td>;
}
