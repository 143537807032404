import React, { ReactElement } from "react";
import Settings from "../../../themes/settings";

interface ChatBubble {
	content: ReactElement[];
	left: boolean;
}
export function ChatBubble({ content, left }: ChatBubble) {
	const colStart = left ? "col-start-1" : "col-start-5";
	const colEnd = left ? "col-end-9" : "col-end-13";
	const imgUrl = Settings.folder + (left ? "/AI-icon.png" : "/user-icon.png");
	const bg = left ? "bg-secondary-light" : "bg-white";
	const order = left ? "flex-row" : "flex-row-reverse justify-start";

	return (
		<div className={`${colStart} ${colEnd} p-3 rounded-sm transition-all`}>
			<div className={`flex items-center ${order}`}>
				<div className="flex items-center justify-center h-10 w-10 rounded-full bg-transparent flex-shrink-0">
					<img src={imgUrl} />
				</div>
				<div
					className={`${bg} relative mx-3 text-sm py-2 px-4 shadow rounded-xl`}
				>
					{content}
				</div>
			</div>
		</div>
	);
}

interface ChatInput {
	inputText: string;
	setInputText: React.Dispatch<React.SetStateAction<string>>;
	handleInputKeyDown: React.KeyboardEventHandler;
	handleClick: React.MouseEventHandler;
}
export function ChatInput({
	inputText,
	setInputText,
	handleInputKeyDown,
	handleClick,
}: ChatInput) {
	return (
		<div className="flex flex-row items-center h-16 rounded-md bg-white w-auto mb-4 mx-4 mt-0">
			<div className="flex-grow px-4">
				<div className="relative w-full">
					<input
						type="text"
						placeholder="Poser une question..."
						onChange={(e) => setInputText(e.target.value)}
						value={inputText}
						onKeyDown={handleInputKeyDown}
						className="flex w-full border rounded-md outline-none focus:outline-0 focus:border-primary pl-4 h-10"
					/>
				</div>
			</div>
			<div className="mr-3">
				<button
					className="flex items-center justify-center bg-primary hover:bg-primary-hover rounded-md text-white pl-2 pr-3 py-3 flex-shrink-0"
					onClick={handleClick}
				>
					<span className="ml-2">
						<svg
							className="w-4 h-4 transform rotate-45 -mt-px"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
							/>
						</svg>
					</span>
				</button>
			</div>
		</div>
	);
}
