import axios from "axios";
import React, { useCallback, useContext, useState } from "react";
import { UserContext } from "../../UserContext";
import { DropZone, LoadingDropZone } from "./UI/DropZone";

export default function FileUpload({ handleFilesChange }: any) {
	const [loading, setLoading] = useState(false);
	const userId = useContext(UserContext);

	const onFileUpload = async (files: FileList) => {
		if (!files) {
			console.log("File is required.");
			return;
		}

		const processed_files: { [key: string]: string } = {};
		for (const file of files) {
			const formData = new FormData();
			formData.append("upload_file", file);

			await axios
				.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload`, formData, {
					responseType: "arraybuffer",
					headers: {
						"Content-Type": "multipart/form-data",
						Accept: "application/pdf",
						"user-id": userId,
					},
				})
				.then((response) => {
					console.log("File uploaded successfully");
					const processed_file_obj = new File([response.data], file.name, {
						type: "application/pdf",
					});
					processed_files[file.name] = URL.createObjectURL(processed_file_obj);
				})
				.catch((error) => {
					console.error("Error uploading file:", error);
					if (error.response) {
						console.error("Response data:", error.response.data);
						console.error("Response status:", error.response.status);
						console.error("Response headers:", error.response.headers);
					}
					console.log("Error uploading file");
				});
		}
		return processed_files;
	};

	const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;
		setLoading(true);
		const processed_files = await onFileUpload(event.target.files);
		setLoading(false);
		handleFilesChange(processed_files);
	};

	const onDrop = useCallback(
		(event: React.DragEvent) => {
			if (!event.dataTransfer) return;

			event.preventDefault();
			const files = event.dataTransfer.files;
			onFileUpload(files);
		},
		[onFileUpload],
	);

	function onDragOver(event: React.DragEvent) {
		event.preventDefault();
	}

	return loading ? (
		<LoadingDropZone />
	) : (
		<DropZone
			onDrop={onDrop}
			onDragOver={onDragOver}
			onFileChange={onFileChange}
		/>
	);
}
