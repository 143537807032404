import React, { useState } from "react";
import { Thumbnail, ThumbnailAdd } from "./Thumbnail";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

interface ThumbnailsGallery {
	uploadedFiles: React.RefObject<any>;
	currentFile: string;
	setCurrentFile: React.Dispatch<string>;
	handleFilesChange: (files: string[]) => void;
}

export default function ThumbnailsGallery({
	uploadedFiles,
	currentFile,
	setCurrentFile,
	handleFilesChange,
}: ThumbnailsGallery) {
	const [loading, setLoading] = useState(false);
	const [loadingFileName, setLoadingFileName] = useState("");

	return (
		<div className="w-full mx-auto bg-primary-100 min-w-0 border-t border-primary-800">
			<div className="flex w-full overflow-x-scroll">
				{Object.keys(uploadedFiles.current).map((file, index) => (
					<div>
						{uploadedFiles.current[file] === "blob:" ? (
							<div key={index}>
								<ThumbnailAdd
									index={index}
									file={file}
									setCurrentFile={setCurrentFile}
									uploadedFiles={uploadedFiles}
									isCurrent={file === currentFile}
									handleFilesChange={handleFilesChange}
									loading={loading}
									setLoading={setLoading}
									loadingFileName={loadingFileName}
									setLoadingFileName={setLoadingFileName}
								/>
							</div>
						) : (
							<div key={index}>
								<Thumbnail
									index={index}
									file={file}
									setCurrentFile={setCurrentFile}
									uploadedFiles={uploadedFiles}
									isCurrent={file === currentFile}
									handleFilesChange={handleFilesChange}
									loading={loading}
									setLoading={setLoading}
									loadingFileName={loadingFileName}
									setLoadingFileName={setLoadingFileName}
								/>
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
}
