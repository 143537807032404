import { Plugin, RenderViewer, Viewer } from "@react-pdf-viewer/core";
import {
	ThumbnailPluginProps,
	thumbnailPlugin,
} from "@react-pdf-viewer/thumbnail";
import axios from "axios";
import React, { memo, useContext } from "react";
import { UserContext } from "../UserContext";

import { assert } from "console";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";

export interface PageThumbnailPluginProps {
	PageThumbnail: React.ReactElement;
}

export const pageThumbnailPlugin = (
	props: PageThumbnailPluginProps,
): Plugin => {
	const { PageThumbnail } = props;

	return {
		renderViewer: (renderProps: RenderViewer) => {
			const { slot } = renderProps;
			slot.children = PageThumbnail;

			// Reset the sub slot
			if (slot.subSlot) {
				slot.subSlot.attrs = {};
				slot.subSlot.children = <></>;
			}

			return slot;
		},
	};
};

interface ThumbnailViewer {
	fileUrl: string;
}
function ThumbnailViewer({ fileUrl }: ThumbnailViewer) {
	const thumbnailPluginInstance = thumbnailPlugin({ thumbnailWidth: 32 });
	const { Cover } = thumbnailPluginInstance;

	const pageThumbnailPluginInstance = pageThumbnailPlugin({
		PageThumbnail: <Cover getPageIndex={() => 0} />,
	});

	return (
		<Viewer
			fileUrl={fileUrl}
			plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
		/>
	);
}

const MemoizedThumbnailViewer = memo(ThumbnailViewer);

const onFileUpload = async (
	fileName: string,
	userId: string,
	originalFile: File,
) => {
	if (!originalFile) {
		console.log("File is required.");
		return;
	}
	const processed_files: { [key: string]: string } = {};
	const formData = new FormData();
	const file = new File(
		[new Blob([originalFile], { type: originalFile.type })],
		fileName === "UPLOAD-NEW-FILE" ? originalFile.name : fileName,
		{ type: originalFile.type },
	);
	formData.append("upload_file", file);

	await axios
		.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload`, formData, {
			responseType: "arraybuffer",
			headers: {
				"Content-Type": "multipart/form-data",
				Accept: "application/pdf",
				"user-id": userId,
			},
		})
		.then((response) => {
			console.log("File uploaded successfully");
			const processed_file_obj = new File([response.data], file.name, {
				type: "application/pdf",
			});
			processed_files[file.name] = URL.createObjectURL(processed_file_obj);
		})
		.catch((error) => {
			console.error("Error uploading file:", error);
			if (error.response) {
				console.error("Response data:", error.response.data);
				console.error("Response status:", error.response.status);
				console.error("Response headers:", error.response.headers);
			}
			console.log("Error uploading file");
		});
	return processed_files;
};

interface Thumbnail {
	index: number;
	file: string;
	setCurrentFile: React.Dispatch<string>;
	uploadedFiles: React.RefObject<any>;
	isCurrent: boolean;
	handleFilesChange: any;
	loading: boolean;
	setLoading: React.Dispatch<boolean>;
	loadingFileName: string;
	setLoadingFileName: React.Dispatch<string>;
}
export function Thumbnail({
	index,
	file,
	setCurrentFile,
	uploadedFiles,
	isCurrent,
	handleFilesChange,
	loading,
	setLoading,
	loadingFileName,
	setLoadingFileName,
}: Thumbnail) {
	const fileUrl = uploadedFiles.current[file];
	return (
		<div className="flex-none p-2 pb-4 first:pl-2 last:pr-2 h-full" key={index}>
			<label htmlFor="dropzone-file-noadd">
				<div
					className={`flex flex-col items-center justify-center gap-5
                  border border-primary-100 rounded shadow h-[110px]
                  transition ease-in-out delay-250 ${
										isCurrent
											? "bg-sky-50 text-primary-800 border-primary-300"
											: "bg-primary-100 text-primary-600 border-sky-50 hover:bg-sky-50 hover:text-primary-800 hover:border-primary-300"
									}`}
					onClick={() => setCurrentFile(file)}
				>
					<div className={"p-1 rounded mb-[-18px] hover:cursor-pointer"}>
						<MemoizedThumbnailViewer fileUrl={fileUrl} />
					</div>
					<div
						className={
							"w-[100px] break-word text-center rounded p-1 hover:cursor-pointer"
						}
					>
						<span className="text-xs font-medium">{file}</span>
					</div>
				</div>
			</label>
		</div>
	);
}

export function ThumbnailAdd({
	index,
	file,
	setCurrentFile,
	uploadedFiles,
	isCurrent,
	handleFilesChange,
	loading,
	setLoading,
	loadingFileName,
	setLoadingFileName,
}: Thumbnail) {
	const userId = useContext(UserContext);

	const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;
		const file = event.target.files[0];
		setLoading(true);
		const processed_files = await onFileUpload(loadingFileName, userId, file);
		setLoading(false);
		handleFilesChange(processed_files);
		setLoadingFileName("");
	};

	return (
		<div className="flex-none p-2 pb-4 first:pl-2 last:pr-2 h-full" key={index}>
			<label htmlFor="dropzone-file">
				<div
					className={`flex flex-col items-center justify-center gap-5
                  border border-primary-100 rounded shadow h-[110px]
                  transition ease-in-out delay-250 ${
										isCurrent
											? "bg-sky-50 text-primary-800 border-primary-300"
											: "bg-primary-100 text-primary-600 border-sky-50 hover:bg-sky-50 hover:text-primary-800 hover:border-primary-300"
									}`}
					onClick={() => {
						if (loadingFileName) {
							console.log("already set");
						} else {
							setLoadingFileName(file);
						}
						console.log(`upload file ${loadingFileName}.`);
					}}
				>
					<input
						id="dropzone-file"
						className="hidden"
						type="file"
						accept="application/pdf"
						onChange={onFileChange}
						key={index}
					/>
					<div className={"p-1 rounded mb-[-18px] hover:cursor-pointer"}>
						{loading && loadingFileName === file ? (
							<svg
								aria-hidden="true"
								role="status"
								className="inline w-12 h-12 mb-2 text-primary-400 animate-spin"
								viewBox="0 0 100 101"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
									fill="#E5E7EB"
								/>
								<path
									d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
									fill="currentColor"
								/>
							</svg>
						) : (
							<img className="w-12 mb-2" src="add-file.png" />
						)}
					</div>
					<div
						className={
							"w-[100px] break-word text-center rounded p-1 hover:cursor-pointer"
						}
					>
						<span className="text-xs font-medium">Ajouter un fichier</span>
					</div>
				</div>
			</label>
		</div>
	);
}
