import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import React from "react";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

interface PDFViewer {
	uploadedFiles: React.RefObject<any>;
	currentFile: string;
	highlightPluginInstance: any;
}

export default function PDFViewer({
	uploadedFiles,
	currentFile,
	highlightPluginInstance,
}: PDFViewer) {
	const toolbarPluginInstance = toolbarPlugin({
		getFilePlugin: {
			fileNameGenerator: (file) => {
				const fileName = file.name.substring(file.name.lastIndexOf("/") + 1);
				return `a-copy-of-${fileName}`;
			},
		},
	});
	const { Toolbar } = toolbarPluginInstance;

	if (currentFile && uploadedFiles.current[currentFile] !== "blob:") {
		return (
			<div className="h-[calc(100vh-182px)] overflow-y-hidden">
				<div className="bg-primary-200 border-b border-t border-white">
					<Toolbar />
				</div>
				<div className="flex flex-col flex-auto bg-white h-full max-h-full overflow-y-hidden">
					<Viewer
						fileUrl={uploadedFiles.current[currentFile]}
						plugins={[toolbarPluginInstance, highlightPluginInstance]}
						defaultScale={SpecialZoomLevel.PageWidth}
					/>
				</div>
			</div>
		);
	} else {
		return (
			<div className="flex flex-col flex-auto flex-shrink-0 bg-primary-100 h-[calc(100vh-70px)] p-4">
				<p>Aucun PDF n'a été importé.</p>
			</div>
		);
	}
}
