import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../UserContext";
import Settings from "../../themes/settings";
import { ChatBubble, ChatInput } from "./UI/ChatUI";
import chatParser from "./functions/chatParser";
import sendQuestion from "./functions/sendQuestion";
import { ChatMessage } from "./types";

interface Chat {
	shouldReset: boolean;
	setShouldReset: React.Dispatch<boolean>;
	uploadedFiles: React.RefObject<any>;
	handleReferenceClick: any;
	canAskQuestion: boolean;
	setCanAskQuestion: React.Dispatch<boolean>;
}
export default function Chat({
	shouldReset,
	setShouldReset,
	uploadedFiles,
	handleReferenceClick,
	canAskQuestion,
	setCanAskQuestion,
}: Chat) {
	const userId = useContext(UserContext);
	const [inputText, setInputText] = useState("");
	const firstMessage: ChatMessage = {
		type: "answer",
		text: Settings.first,
	};
	const [chatMessages, setChatMessages] = useState([firstMessage]);
	const [chatQuestion, setChatQuestion] = useState("");
	const chatContainerRef = useRef<HTMLDivElement>(null);

	function handleSendQuestion(question: string) {
		return sendQuestion(
			question,
			setCanAskQuestion,
			setChatMessages,
			chatMessages,
			uploadedFiles,
			userId,
		);
	}

	// Déclencher la question
	useEffect(() => {
		if (canAskQuestion) {
			handleSendQuestion(chatQuestion);
		}
	}, [chatQuestion]);

	// Scroll automatique à l'écriture de réponses.
	useEffect(() => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop =
				chatContainerRef.current.scrollHeight;
		}
	}, [chatMessages]);

	// Effacer le chat en cas de chargement d'un nouveau document
	useEffect(() => {
		if (shouldReset) {
			setChatMessages([]);
			setShouldReset(false);
		}
	}, [shouldReset]);

	// Permet à l'utilisateur d'appuyer sur entrer pour envoyer sa question
	const handleInputKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter" && canAskQuestion) {
			handleSendQuestion(inputText);
			setInputText("");
		}
	};

	const handleClick = (_: React.MouseEvent) => {
		if (canAskQuestion) {
			handleSendQuestion(inputText);
			setInputText("");
		}
	};

	return (
		<div className="flex flex-col flex-auto flex-shrink-0 h-[calc(100vh-45px)]">
			<div
				className="flex flex-col h-full overflow-x-auto mb-4"
				ref={chatContainerRef}
			>
				<div className="flex flex-col h-full">
					{chatMessages.map((message, index) => (
						<div className="grid grid-cols-12 gap-y-2" key={index}>
							{message.type === "question" ? (
								<ChatBubble
									content={chatParser(
										message.text,
										canAskQuestion,
										handleReferenceClick,
										setChatQuestion,
									)}
									left={false}
								/>
							) : message.type === "answer" ? (
								<ChatBubble
									content={chatParser(
										message.text,
										canAskQuestion,
										handleReferenceClick,
										setChatQuestion,
									)}
									left={true}
								/>
							) : null}
						</div>
					))}
				</div>
			</div>
			<ChatInput
				inputText={inputText}
				setInputText={setInputText}
				handleInputKeyDown={handleInputKeyDown}
				handleClick={handleClick}
			/>
		</div>
	);
}
