import { DomUtils } from "htmlparser2";
import { marked } from "marked";
import ReactHtmlParser, { processNodes } from "react-html-parser";
import { Question, Reference } from "../UI/ChatButtons";
import {
	ChatTBody,
	ChatTD,
	ChatTH,
	ChatTHead,
	ChatTable,
} from "../UI/ChatTable";

export default function chatParser(
	text: string,
	canAskQuestion: boolean,
	handleReferenceClick: any,
	setChatQuestion: React.Dispatch<string>,
) {
	function transform(node: any, index: number) {
		if (node.type === "tag") {
			const content = processNodes(node.children, transform);
			switch (node.name) {
				case "button":
					if (node.attribs.class === "highliht-reference") {
						const doc = node.attribs["data-doc"];
						const page = node.attribs["data-page"];
						const left = node.attribs["data-left"];
						const top = node.attribs["data-top"];
						const width = node.attribs["data-width"];
						const height = node.attribs["data-height"];
						const onClick = () =>
							handleReferenceClick(doc, page, left, top, width, height);
						return (
							<Reference
								text={DomUtils.getText(node)}
								onClick={onClick}
								index={index}
							/>
						);
					}
					if (node.attribs.class === "question") {
						const content = processNodes(node.children, transform);
						const onClick = () => {
							if (canAskQuestion) {
								setChatQuestion(DomUtils.getText(node));
							}
						};
						return <Question content={content} onClick={onClick} />;
					}
					break;
				case "table":
					return <ChatTable content={content} />;
				case "thead":
					return <ChatTHead content={content} />;
				case "th":
					return <ChatTH content={content} />;
				case "tbody":
					return <ChatTBody content={content} />;
				case "td":
					return <ChatTD content={content} />;
			}
		}
	}

	const parserOptions = {
		decodeEntities: true,
		transform: transform,
	};

	return ReactHtmlParser(marked.parse(text), parserOptions);
}
